import Utility from "../../../shared/utility.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "./addFav.js";
import EncryptUtility from "./encrypt-utility.js";
import utilityService from "./utility-service.js";
import commonService from "../../common/js/common-api-service.js";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      selectedProject: "",
      projectList: [],
      selectedUser: "",
      userList: [],
      userDisabled: false,
      totalRecords: 0,
      projectSelected: true,
      userSelected: false,
      auditProjectItems: [],
      auditUserItems: [],
      searchProject: "",
      searchUser: "",
      auditDialog: false,
      auditByProject: false,
      auditByUser: false,
      selectedProjectAudit: "",
      auditRateProject: 0,
      activeStatusProject: false,
      rulesPostive: [(v) => parseInt(v) > 0 || "Field should be greater than 0", (v) => parseInt(v) < 100 || "Field should be less than 100"],
      selectedUserAudit: "",
      selectedProjectAuditUser: "",
      auditRateUser: 0,
      activeStatusUser: false,
      projectListAudit: [],
      userListAudit: [],
      auditSelected: false,
      projectDetails: [],
      userDetails: [],
      projectListAuditEdit: [],
      projIncludeAll: 0,
      userIncludeAll: 0,
      isFormValid: false,
      searchDisabled: false,
      projectListAuditUser: [],
      projectListAuditEditUser: [],
      required: [(v) => !!v || "Field is required"],
      deleteAuditDialog: false,
      editAuditDetails: [],
      editAudit: true,
      auditRateRules: [(v) => /(100)|(0*\d{1,2})/.test(v) || "Audit Rate has to be below 100 and greater than 0"],
      auditHeadersForProject: [
        { text: "Project", value: "project", class: "primary customwhite--text" },
        { text: "Audit Rate", value: "audit_rate", class: "primary customwhite--text" },
        { text: "Created By", value: "cby", class: "primary customwhite--text" },
        { text: "Created Date", value: "cdatetime", class: "primary customwhite--text" },
        { text: "Status", value: "active", class: "primary customwhite--text" },
        { text: "Actions", value: "actions", class: "primary customwhite--text" },
      ],
      auditHeadersForUser: [
        { text: "User", value: "name", class: "primary customwhite--text" },
        { text: "Project", value: "project", class: "primary customwhite--text" },
        { text: "Audit Rate", value: "audit_rate", class: "primary customwhite--text" },
        { text: "Created By", value: "cby", class: "primary customwhite--text" },
        { text: "Created Date", value: "cdatetime", class: "primary customwhite--text" },
        { text: "Status", value: "active", class: "primary customwhite--text" },
        { text: "Actions", value: "actions", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjUserData();
  },
  mounted() {},
  methods: {
    // to make sure user can only add number value
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.projectList = [];
      this.selectedUser = "";
      this.userList = [];
      this.userDisabled = false;
      this.totalRecords = 0;
      this.projectSelected = true;
      this.userSelected = false;
      this.auditProjectItems = [];
      this.auditUserItems = [];
      this.searchDisabled = false;
      this.deleteAuditDialog = false;
      this.editAuditDetails = [];
      this.editAudit = true;
      this.$refs.fulfillmentSearchForm?.resetValidation();
      this.onCancel();
      this.getProjUserData();
    },
    //Project and User Data
    async getProjUserData() {
      let projData = await commonService.getProjectAll("get", this.userId);
      if (projData != null && projData != undefined && projData != {}) {
        this.projectList = projData.ProjectInfo;
      }
      let obj = {
        Pass: 1,
        UserId: parseInt(this.userId),
        json: "",
      };
      let showMessage = false;
      let userData = await utilityService.fulfilmentConfigSearch("post", obj, showMessage);
      if (userData != null && userData != undefined && userData != {}) {
        this.userList = userData.UserInfo;
        this.userList.unshift(JSON.parse('{"UserId":-1, "Name":"ALL"}'));
      }
    },
    //Audit by Project change
    async projectAudit() {
      this.auditByUser = false;
      this.selectedUserAudit = "";
      this.selectedProjectAuditUser = "";
      this.auditRateUser = 0;
      this.activeStatusUser = false;
      this.auditSelected = true;
      let obj = {
        Pass: 4,
        UserId: parseInt(this.userId),
        json: "",
      };
      let showMessage = false;
      let response = await utilityService.fulfilmentConfigSearch("post", obj, showMessage);
      if (response != {} && response != undefined && response != null) {
        this.projectListAudit = response.ProjectData;
      }
    },
    //Audit by User change
    async userAudit() {
      this.auditByProject = false;
      this.selectedProjectAudit = "";
      this.auditRateProject = 0;
      this.activeStatusProject = false;
      this.auditSelected = true;
      let objProject = {
        Pass: 0,
        UserId: parseInt(this.userId),
        json: "",
      };
      let showMessage = false;
      let projectData = await utilityService.fulfilmentConfigSearch("post", objProject, showMessage);
      if (projectData != {} && projectData != undefined && projectData != null) {
        this.projectListAuditUser = projectData.ProjectInfo;
      }
      let objUser = {
        Pass: 5,
        UserId: parseInt(this.userId),
        json: "",
      };
      let userData = await utilityService.fulfilmentConfigSearch("post", objUser, showMessage);
      if (userData != {} && userData != undefined && userData != null) {
        this.userListAudit = userData.UserData;
      }
    },
    //New Item click event
    newItemClicked() {
      this.auditDialog = false;
      this.auditByProject = false;
      this.auditByUser = false;
      this.selectedProjectAudit = "";
      this.auditRateProject = 0;
      this.activeStatusProject = false;
      this.selectedUserAudit = "";
      this.selectedProjectAuditUser = "";
      this.auditRateUser = 0;
      this.activeStatusUser = false;
      this.projectListAuditEdit = [];
      this.userListAudit = [];
      this.auditSelected = false;
      this.auditDialog = true;
    },
    //Cancel and close button event
    onCancel() {
      if (this.editAuditDialog) {
        this.projectDetails = [];
        this.userDetails = [];
        this.auditByProject = false;
        this.auditByUser = false;
        this.editAuditDialog = false;
        this.selectedProjectAudit = "";
        this.auditRateProject = 0;
        this.activeStatusProject = false;
        this.selectedUserAudit = "";
        this.selectedProjectAuditUser = "";
        this.auditRateUser = 0;
        this.activeStatusUser = false;
        this.projectListAuditEdit = [];
        this.userListAudit = [];
        this.auditSelected = false;
        this.editAudit = true;
      } else if (this.auditDialog) {
        this.auditDialog = false;
        this.auditByProject = false;
        this.auditByUser = false;
        this.selectedProjectAudit = "";
        this.auditRateProject = 0;
        this.activeStatusProject = false;
        this.selectedUserAudit = "";
        this.selectedProjectAuditUser = "";
        this.auditRateUser = 0;
        this.activeStatusUser = false;
        this.projectListAuditEdit = [];
        this.userListAudit = [];
        this.auditSelected = false;
        if (this.auditByProject) {
          this.$refs.projectAuditForm.resetValidation();
        } else if (this.auditByUser) {
          this.$refs.userAuditForm.resetValidation();
        }
      } else if (this.deleteAuditDialog) {
        this.deleteAuditDialog = false;
      }
    },
    //Audit Rate of Project
    changeAuditRateProject() {
      if (this.auditRateProject <= 0) {
        this.activeStatusProject = false;
      } else {
        this.activeStatusProject = true;
      }
    },
    //Audit Rate of User
    changeAuditRateUser() {
      if (this.auditRateUser <= 0) {
        this.activeStatusUser = false;
      } else {
        this.activeStatusUser = true;
      }
    },
    //Project Change
    projectChange() {
      this.selectedUser = "";
      this.userSelected = false;
      this.searchDisabled = false;
    },
    //User Change
    userChange() {
      this.selectedProject = "";
      this.projectSelected = false;
      this.searchDisabled = false;
    },
    //Search Cancel
    onSearchCancel() {
      this.$refs.fulfillmentSearchForm?.resetValidation();
      this.selectedProject = "";
      this.selectedUser = "";
      this.projectSelected = true;
      this.userSelected = false;
      this.totalRecords = 0;
      this.searchDisabled = false;
      this.auditProjectItems = [];
      this.auditUserItems = [];
    },
    //Project Actions button click event
    async editProjectAudit(item) {
      this.editAuditDetails = [];
      this.editAuditDetails = item;
      this.projectDetails = [];
      let searchJson = JSON.stringify({
        audit_id: item.audit_id,
      });
      let obj = {
        Pass: 9,
        UserId: parseInt(this.userId),
        json: searchJson,
      };
      let showMessage = false;
      let response = await utilityService.fulfilmentConfigSearch("post", obj, showMessage);
      if (response !== null && response !== undefined) {
        let obj = {
          Pass: 0,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        let showMessage = false;
        let data = await utilityService.fulfilmentConfigSearch("post", obj, showMessage);
        if (data != {} && data !== null && data !== undefined) {
          this.projectListAuditEdit = data.ProjectInfo;
        }
        this.projectDetails = response.ProjectAuditById;
        this.projectAudit();
        this.auditByProject = true;
        this.selectedProjectAudit = response.ProjectAuditById[0].proj_key;
        this.auditRateProject = response.ProjectAuditById[0].audit_rate;
        this.activeStatusProject = response.ProjectAuditById[0].active;
        this.editAuditDialog = true;
        this.auditSelected = true;
      }
    },
    //User Actions button click event
    async editUserAudit(item) {
      this.editAuditDetails = [];
      this.editAuditDetails = item;
      this.userDetails = [];
      let objUser = {
        Pass: 1,
        UserId: parseInt(this.userId),
        json: "",
      };
      let messageShow = false;
      let userData = await utilityService.fulfilmentConfigSearch("post", objUser, messageShow);
      if (userData != {} && userData != undefined && userData != null) {
        this.userListAudit = userData.UserInfo;
      }
      let searchJson = JSON.stringify({
        audit_id: item.audit_id,
      });
      let obj = {
        Pass: 10,
        UserId: parseInt(this.userId),
        json: searchJson,
      };
      let showMessage = false;
      let response = await utilityService.fulfilmentConfigSearch("post", obj, showMessage);
      if (response !== null && response !== undefined) {
        let obj = {
          Pass: 0,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        let showMessage = false;
        let data = await utilityService.fulfilmentConfigSearch("post", obj, showMessage);
        if (data != {} && data !== null && data !== undefined) {
          this.projectListAuditEditUser = data.ProjectInfo;
        }
        this.userDetails = response.UserAuditById;
        this.projectAudit();
        this.auditByUser = true;
        this.selectedUserAudit = response.UserAuditById[0].user_key;
        this.selectedProjectAuditUser = response.UserAuditById[0].proj_key;
        this.auditRateUser = response.UserAuditById[0].audit_rate;
        this.activeStatusUser = response.UserAuditById[0].active;
        this.editAuditDialog = true;
        this.auditSelected = true;
      }
    },
    //Search button click event
    async onSearchClick() {
      if (this.$refs.fulfillmentSearchForm?.validate()) {
        if (this.selectedProject != "") {
          if (this.selectedProject.includes(0)) {
            this.projIncludeAll = 1;
          } else {
            this.projIncludeAll = 0;
          }
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let searchJson = JSON.stringify({
            all_record: this.projIncludeAll,
            proj_key: this.selectedProject,
          });
          let obj = {
            Pass: 2,
            UserId: parseInt(this.userId),
            json: searchJson,
          };
          this.axios
            .post("co/fulfilment_audit_config", obj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let data = JSON.parse(response.data.body.message);
                const newArr = data.AuditDataByProject.map((obj) => {
                  return {
                    ...obj,
                    cdatetime: Utility.convertESTToLocal(obj.cdatetime),
                  };
                });
                this.auditProjectItems = newArr;
                this.totalRecords = newArr.length;
                this.projectSelected = true;
                this.searchDisabled = true;
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                var Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              this.projectSelected = true;
              this.totalRecords = 0;
              this.auditProjectItems = [];
              this.auditUserItems = [];
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        } else if (this.selectedUser != "") {
          if (this.selectedUser.includes(-1)) {
            this.userIncludeAll = 1;
          } else {
            this.userIncludeAll = 0;
          }
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let searchJson = JSON.stringify({
            all_record: this.userIncludeAll,
            userid: this.selectedUser,
          });
          let obj = {
            Pass: 3,
            UserId: parseInt(this.userId),
            json: searchJson,
          };
          this.axios
            .post("co/fulfilment_audit_config", obj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let data = JSON.parse(response.data.body.message);
                const newArr = data.AuditDataByUser.map((obj) => {
                  return {
                    ...obj,
                    cdatetime: Utility.convertESTToLocal(obj.cdatetime),
                  };
                });
                this.auditUserItems = newArr;
                this.totalRecords = newArr.length;
                this.userSelected = true;
                this.projectSelected = false;
                this.searchDisabled = true;
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                var Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              this.projectSelected = true;
              this.auditProjectItems = [];
              this.auditUserItems = [];
              this.totalRecords = 0;
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      }
    },
    //Refreshing the Audit Dialog
    refreshAuditDialog() {
      if (this.auditDialog) {
        this.auditByProject = false;
        this.auditByUser = false;
        this.selectedProjectAudit = "";
        this.auditRateProject = 0;
        this.activeStatusProject = false;
        this.selectedUserAudit = "";
        this.selectedProjectAuditUser = "";
        this.auditRateUser = 0;
        this.activeStatusUser = false;
        this.projectListAudit = [];
        this.userListAudit = [];
        this.auditSelected = false;
      }
    },
    //Add button in New item
    clickAddAudit() {
      if (this.auditByUser) {
        if (this.$refs.userAuditForm.validate()) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let user = [this.selectedUserAudit];
          let project = [this.selectedProjectAuditUser];
          let validateJson = JSON.stringify({
            userid: user,
            proj_key: project,
          });
          let validateObj = {
            Pass: 8,
            UserId: parseInt(this.userId),
            json: validateJson,
          };
          this.axios
            .post("co/fulfilment_audit_config", validateObj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let searchJson = JSON.stringify({
                  audit_id: "1",
                  audit_rate: this.auditRateUser,
                  userid: user,
                  proj_key: project,
                  active: this.activeStatusUser == true ? 1 : 0,
                  process: "",
                });
                let obj = {
                  Pass: 6,
                  UserId: parseInt(this.userId),
                  json: searchJson,
                };
                this.axios
                  .post("co/fulfilment_audit_config", obj)
                  .then((response) => {
                    if (response.status == 200 || response.status == 204) {
                      this.onCancel();
                      this.onSearchClick();
                      LoaderDialog.visible = false;
                      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                      let Alert = {
                        type: "success",
                        isShow: true,
                        message: response.data.body.message,
                      };
                      this.$store.commit("ConfigModule/Alert", Alert);
                      this.$refs.fulfillmentSearchForm?.resetValidation();
                    } else {
                      LoaderDialog.visible = false;
                      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                      let Alert = {
                        type: "error",
                        isShow: true,
                        message: response.data.message,
                      };
                      this.$store.commit("ConfigModule/Alert", Alert);
                    }
                    LoaderDialog.visible = false;
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  })
                  .catch((error) => {
                    LoaderDialog.visible = false;
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                    let Alert = {
                      type: "error",
                      isShow: true,
                      message: Utility.apiError(error),
                    };
                    this.$store.commit("ConfigModule/Alert", Alert);
                  });
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            });
        }
      } else if (this.auditByProject) {
        if (this.$refs.projectAuditForm.validate()) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let project = [this.selectedProjectAudit];
          let searchJson = JSON.stringify({
            audit_id: "1",
            audit_rate: this.auditRateProject,
            userid: "",
            proj_key: project,
            active: this.activeStatusProject == true ? 1 : 0,
            process: "",
          });
          let obj = {
            Pass: 7,
            UserId: parseInt(this.userId),
            json: searchJson,
          };
          this.axios
            .post("co/fulfilment_audit_config", obj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                this.onCancel();
                this.onSearchClick();
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.body.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.$refs.fulfillmentSearchForm?.resetValidation();
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            });
        }
      }
    },
    //Update button in Edit popup
    clickUpdateAudit() {
      if (this.auditByProject) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let project = [this.selectedProjectAudit];
        let searchJson = JSON.stringify({
          audit_id: this.projectDetails[0].audit_id,
          audit_rate: this.auditRateProject,
          userid: "",
          proj_key: project,
          active: this.activeStatusProject == true ? 1 : 0,
          process: "",
        });
        let obj = {
          Pass: 11,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        this.axios
          .post("co/fulfilment_audit_config", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.onCancel();
              this.onSearchClick();
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      } else if (this.auditByUser) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let user = [this.selectedUserAudit];
        let project = [this.selectedProjectAuditUser];
        let searchJson = JSON.stringify({
          audit_id: this.userDetails[0].audit_id,
          audit_rate: this.auditRateUser,
          userid: user,
          proj_key: project,
          active: this.activeStatusUser == true ? 1 : 0,
          process: "",
        });
        let obj = {
          Pass: 12,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        this.axios
          .post("co/fulfilment_audit_config", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.onCancel();
              this.onSearchClick();
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },
    //Delete button click event
    auditDelete() {
      if (this.auditByProject) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let searchJson = JSON.stringify({
          audit_id: this.editAuditDetails.audit_id,
        });
        let obj = {
          Pass: 14,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        this.axios
          .post("co/fulfilment_audit_config", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.deleteAuditDialog = false;
              this.editAuditDetails = [];
              this.editAuditDialog = false;
              this.onSearchClick();
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      } else if (this.auditByUser) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let searchJson = JSON.stringify({
          audit_id: this.editAuditDetails.audit_id,
        });
        let obj = {
          Pass: 13,
          UserId: parseInt(this.userId),
          json: searchJson,
        };
        this.axios
          .post("co/fulfilment_audit_config", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.deleteAuditDialog = false;
              this.editAuditDetails = [];
              this.editAuditDialog = false;
              this.onSearchClick();
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },
  },
  components: {
    breadcrumbComp,
  },
};
